<!-- GET /repair/apply 维修详情接口 -->
<!-- GET /repair/record 维修记录接口 -->
<!-- GET /repair/fee 维修费用详情 -->
<template>
  <div>
    <!-- 头部 -->
    <div style="margin: -2em -2em 0px">
      <a-card :bordered="false">
        <DescriptionList>
          <div class="titleBoxL titleBox">
            <label class="title">
              维修详情
              <span v-if="applyObj.statusName == '维修中'" class="tagA">{{
                applyObj.statusName
              }}</span>
              <span v-else-if="applyObj.statusName == '验收中'" class="tagB">{{
                applyObj.statusName
              }}</span>
              <span v-else-if="applyObj.statusName == '已完成'" class="tagC">{{
                applyObj.statusName
              }}</span>
            </label>
          </div>
          <div style="margin-left: 54px;width: 90%">
            <Description term="城市" class="first"
              ><label>{{ applyObj.cityName }}</label>
            </Description>
            <Description term="厨房品牌" class="first"
              ><label>{{ applyObj.kitchenBrandName }}</label>
            </Description>
            <Description term="门店名称" class="first"
              ><label>{{ applyObj.facilityName }}</label>
            </Description>
            <Description term="档口编号"
              ><label>{{ applyObj.kitchenCode }}</label>
            </Description>
            <Description term="店铺名称"
              ><label>{{ applyObj.storeName }}</label>
            </Description>
          </div>
        </DescriptionList>
      </a-card>
    </div>
    <div style="margin: -1em -2em 2em;margin-bottom:2em;">
      <a-card
        style="width:100%;padding-top:2em;"
        :bordered="false"
        :tabList="tabList"
        :activeTabKey="tabKey"
        @tabChange="key => onTabChange(key)"
      >
      </a-card>
    </div>
    <!-- 切换数据请求 -->
    <a-spin :spinning="dataLoading">
      <div v-show="tabKey == 'repairDetail'" class="tagBox">
        <div class="titleBox">
          <label class="title">维修记录</label>
        </div>
        <a-divider />
        <!-- 外层大循环 -->
        <div v-for="(item, index) in recordMainList" v-bind:key="index">
          <div class="cardBorder">
            <span class="dot"></span>
            <Description term="申请人">
              <label>{{ item.applyStaffName }}</label>
              <span v-if="index > 0" class="reOpen">重开</span>
            </Description>
            <Description term="申请日期">
              <label>{{ item.applyDate }}</label>
            </Description>
            <Description term="维修类目">
              <label>{{ item.typeName }}</label>
            </Description>
            <Description term="紧急程度">
              <label>{{ item.urgencyName }}</label>
            </Description>
            <Description term="维修内容">
              <label>{{ item.remark }}</label>
            </Description>
            <Description term="相关照片" class="imgWarp">
              <label>
                <div class="imgBox" v-if="!!item.picList">
                  <img
                    v-for="(aPic, index) in item.picList"
                    v-bind:key="index"
                    style="width:6em;height:6em;"
                    slot="cover"
                    alt="example"
                    @click="viewImg($event)"
                    :src="aPic"
                  />
                </div>
              </label>
            </Description>
          </div>
          <div v-if="!!item.recordList">
            <div
              class="cardBorder"
              v-for="(rItem, index) in item.recordList"
              v-bind:key="index"
            >
              <span class="dot"></span>
              <Description term="维修人">
                <label>{{ rItem.repairStaffName }}</label>
              </Description>
              <Description term="维修日期">
                <label>{{ rItem.repairDate }}</label>
              </Description>
              <Description term="维修记录">
                <label>{{ rItem.remark }}</label>
              </Description>
              <Description term="相关照片" class="imgWarp">
                <label>
                  <div class="imgBox" v-if="!!rItem.picList">
                    <img
                      v-for="(arPic, index) in rItem.picList"
                      v-bind:key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="arPic"
                    />
                  </div>
                </label>
              </Description>
            </div>
          </div>
          <a-divider dashed />
        </div>
      </div>
      <div v-show="tabKey == 'feeDetail'" class="tagBox">
        <div class="titleBox">
          <label class="title">费用记录</label>
        </div>
        <a-divider />
        <div
          class="feeBorder"
          v-for="(feeObj, index) in feeMainList"
          v-bind:key="index"
        >
          <Description term="申请人">
            <label>{{ feeObj.applyStaffName }}</label>
          </Description>
          <Description term="申请日期">
            <label>{{ feeObj.applyDate }}</label>
          </Description>
          <Description term="门店名称">
            <label>{{ feeObj.facilityName }}</label>
          </Description>
          <Description term="档口编号">
            <label>{{ feeObj.kitchenCode }}</label>
          </Description>
          <Description term="店铺名称">
            <label>{{ feeObj.storeName }}</label>
          </Description>
          <Description term="费用类型">
            <label>{{ feeObj.feeTypeName }}</label>
          </Description>
          <Description term="商户承担费用">
            <label>{{ feeObj.tenantAmount }} 元</label>
          </Description>
          <Description term="我方承担费用">
            <label>{{ feeObj.usAmount }} 元</label>
          </Description>
          <Description term="相关照片" class="imgWarp">
            <label>
              <div class="imgBox" v-if="!!feeObj.picList">
                <img
                  style="width:6em;height:6em;"
                  v-for="(fPic, index) in feeObj.picList"
                  v-bind:key="index"
                  slot="cover"
                  alt="example"
                  @click="viewImg($event)"
                  :src="fPic"
                />
              </div>
            </label>
          </Description>
          <Description term="审批通过日期">
            <label>{{ feeObj.approvalDate }}</label>
          </Description>
          <a-divider dashed />
        </div>
      </div>
    </a-spin>
    <!-- 弹窗 -->
    <a-modal :width="1000" v-model="visibleImg" title="图片详情" :footer="null">
      <img style="width:100%;height:100%;" :src="showImgUrl" />
    </a-modal>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import axios from "axios";
import Bus from "@/bus";
  
export default {
  name: "repairOrderDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      applyId: this.$route.query.applyId,
      applyObj: {},
      tabKey: "repairDetail",
      // tab
      tabList: [
        {
          key: "repairDetail",
          tab: "维修记录"
        }
      ],
      // visibleIMG
      visibleImg: false,
      showImgUrl: "",
      // 数据loading
      dataLoading: false,
      // Record
      recordMainList: [],
      // Fee
      feeMainList: []
      // feeObj:{},
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  computed: {},
  methods: {
    // 初始化
    init() {
      this.initApply();
      this.initRecord();
      this.initFee();
    },
    //基本
    initApply() {
      axios({
        url:   this.$baseUrl + "repair/apply",
        method: "GET",
        params: { applyId: this.applyId }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.applyObj = res.data.obj;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    initRecord() {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "repair/record",
        method: "GET",
        params: { applyId: this.applyId }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.recordMainList = res.data.rows || [];
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    initFee() {
      axios({
        url:   this.$baseUrl + "repair/fee",
        method: "GET",
        params: { applyId: this.applyId }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.feeMainList = res.data.rows || [];
          this.tabList.push({
            key: "feeDetail",
            tab: "费用记录"
          });
        } else if (res.data.returnCode == "201") {
          // 费用栏 清空
          // this.tabList[1] = {};
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // tab改变
    onTabChange(key) {
      if (this.tabKey == key) {
        return;
      }
      this.tabKey = key;
    },
    // 查看照片
    viewImg(e) {
      this.showImgUrl = e.target.src || "";
      this.visibleImg = true;
    }
  }
};
</script>

<style lang="less" scoped>
.tagBox {
  background-color: #fff;
  padding: 2em;
}
.titleBoxL {
  padding-left: 2.5em !important;
}
.titleBox {
  padding: 1em;
}
.title {
  font-size: 1.5em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5em;
}
/* 维修中 */
.tagA {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: rgba(82, 196, 26, 1);
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
/* 验收中 */
.tagB {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: rgba(251, 158, 25, 1);
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
/* 已完成 */
.tagC {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: rgba(151, 151, 151, 1);
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
.imgWarp {
  display: flex;
}
.imgBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  img {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0.5em;
    margin: 0.5em;
  }
}
.cardBorder {
  border-left: 1px solid rgba(191, 24, 102, 1);
  padding-left: 1.5em;
  position: relative;
  padding-bottom: 2.5em;
  padding-top: 0.5em;
  .dot {
    background-color: rgba(191, 24, 102, 1);
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: -0.3em;
  }
}
.cardBorder:nth-last-child(2) {
  border-left: 1px solid transparent;
}
/* 重开字 */
.reOpen {
  margin-left: 1em;
  border: 1px solid rgba(191, 24, 102, 0.72);
  border-radius: 1em;
  padding: 0.1em 0.6em;
  font-size: 0.6em;
  color: rgba(191, 24, 102, 0.72);
}
.feeBorder {
  border-left: 1px solid rgba(191, 24, 102, 1);
  padding-left: 1.5em;
  position: relative;
}
</style>
